import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import { Link } from "gatsby"
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';


export default function IrisGPTAPIDocs() {
  const json = {
  "result":
    {
      "answer": "You can add a card by going to the Payment settings page and clicking on the 'Add card' button.",
      "title_and_urls": [
      {
        "title": "Payment settings for adding cards",
        "url": "https://d3v-irisagent.zendesk.com/hc/en-us/articles/360052484354-Payment-settings-for-adding-cards"
      }
      ]
    }
}

  return (
    <Layout
      keywords="IrisGPT API guide"
      title="Use IrisGPT's API to power intelligent AI answers in your products"
      description="Documentation on how to use IrisGPT's API to power intelligent, Gen-AI answers in your products"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/irisgpt-api-docs/"
        />
      </Helmet>
      <section class="inner-page">
          <div class="container">
            <div data-custom-class="body"
            style={{ marginBottom: "5%", marginTop: "5%", margin: "10%" }}>
            <br/><br/><br/>
                    <h1>IrisGPT API Documentation</h1>
                    <br/>
                    <h2>
                    Introduction
                    </h2>
                    <br/>

                    IrisAgent's <a href="https://irisagent.com/support-agent-assist/">IrisGPT AI answers</a> can be used an API to power intelligent conversations on any of your frontend webpages. It can search through your internal and external knowledge articles, community forums, product documentation, and other informational content.
                    <br/>
                    <br/>
                    <h2>
                    API Guide
                    </h2>
                    <br/>
                    Below is a <span style={{"font-family": "Consolas"}}>curl</span> command to call our IrisGPT API:
                    <SyntaxHighlighter language="javascript" style={prism}>
  {`
curl --location \\
'https://frontend-api-server-v2.api.irisagent.com/v1/irisgpt?query=add%20a%20card&' \\
--header 'X-Business-Id: REPLACE_ME_BUSINESS_ID'
  `}
</SyntaxHighlighter>
<br/>
Here, the inputs are:
<br/><br/>
<ul>
    <li>
    <span style={{"font-family": "Consolas"}}>query</span>: [Required] URL-encoded query from the user
    </li>
    <li>
    <span style={{"font-family": "Consolas"}}>REPLACE_ME_BUSINESS_ID</span>: This is the ID provided by the IrisAgent team
    </li>
</ul>
<br/>
Below is a sample output of the API:
<br/>
<SyntaxHighlighter language="javascript" style={prism}>
{JSON.stringify(json, null, 2)}
</SyntaxHighlighter>

                    </div>
                    </div>
                    </section>

</Layout>
  )
}